import React from 'react';
import { Cursor, Footer, Header, NoJs, WmAscii, SEO } from 'components';
import { SchemaProvider } from '../../context/schema';
import 'typeface-karla';
import 'typeface-pt-mono';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const { children, customSEO, seo } = props;

  return (
    <SchemaProvider>
      <NoJs />
      <WmAscii />
      <Cursor />
      <Header />
      <main>
        {!customSEO && <SEO {...seo} />}
        {children}
      </main>
      <Footer />
    </SchemaProvider>
  );
};

export default Layout;
