import React, { useState, useRef } from 'react';
import { ThemeToggle, Carousel, Image, Section, Icon } from 'components';
import './styles.scss';

const PlainCarousel = props => {
  const { data } = props;
  const { items, primary } = data;
  const { title, tagline, icon, dark_mode: darkMode } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  // state
  const [slickRef, setSlickRef] = useState(0);
  const interval = useRef();

  const handleHover = (event, direction) => {
    // event.preventDefault();
    if (direction === 'forward') {
      slickRef.slickNext();
    } else {
      slickRef.slickPrev();
    }
    interval.current = setInterval(() => {
      if (direction === 'forward') {
        slickRef.slickNext();
      } else {
        slickRef.slickPrev();
      }
    }, 1000);
  };
  const handleHoverExit = () => {
    clearInterval(interval.current);
    if (slickRef) {
      // slickRef.slickPlay();
      slickRef.slickPause();
    }
  };

  // Carousel settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    speed: 500,
    centerMode: true,
    // variableWidth: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="plain-carousel" noWrapper clientOnly>
        {titleHtml && (
          <div className="plain-carousel-header">
            {icon && <Icon iconText={icon} />}
            {taglineHtml && (
              <div className="plain-carousel-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />
            )}
            {titleHtml && <div className="plain-carousel-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          </div>
        )}
        <Carousel settings={settings} sliderRef={setSlickRef}>
          {items.map(item => (
            <Image key={item?.image?.url} image={item?.image} className="plain-carousel-portrait" loading="lazy" />
          ))}
        </Carousel>
        <button
          type="button"
          onMouseOver={event => handleHover(event, 'back')}
          onFocus={() => {}}
          onBlur={() => {}}
          onMouseOut={handleHoverExit}
          className="plain-carousel-button left"
          aria-label="Previous image"
        />
        <button
          type="button"
          onMouseOver={event => handleHover(event, 'forward')}
          onMouseOut={handleHoverExit}
          onFocus={() => {}}
          onBlur={() => {}}
          className="plain-carousel-button right"
          aria-label="Next image"
        />
      </Section>
    </ThemeToggle>
  );
};

export default PlainCarousel;
