import React from 'react';
import { Section, SmallButton, ThemeToggle, Icon } from 'components';
import './styles.scss';

const CardList = props => {
  const { data } = props;
  const { primary, items } = data;
  const { tagline, title, dark_mode: darkMode } = primary;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="card-list">
        <div className="card-list-sticky-container">
          <div className="card-list-title-column">
            {taglineHtml && <div className="card-list-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
            {titleHtml && <div className="card-list-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          </div>
        </div>
        <div className="card-list-sticky-container">
          <div className="card-list-cards-column">
            {items.map((card, index) => {
              const {
                card_content: content,
                card_cta_link: to,
                card_cta_text: ctaText,
                card_icon: icon,
                card_title: cardTitle,
              } = card;
              return (
                <div key={cardTitle?.text} className={`card-list-card-sticky card-${index + 1}`}>
                  <div className="card-list-card">
                    {icon && <Icon iconText={icon} />}
                    <div className="card-list-title" dangerouslySetInnerHTML={{ __html: cardTitle.html }} />
                    <div className="card-list-content" dangerouslySetInnerHTML={{ __html: content.html }} />
                    <SmallButton className="card-list-cta" to={to.url} forceLightMode>
                      {ctaText.text}
                    </SmallButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default CardList;
