import React from 'react';
import { Link } from 'components';
import './styles.scss';

const Button = props => {
  const { to, title, className = '', children, theme = 'primary', outline } = props;
  return (
    <Link to={to} title={title || ''} className={`button ${theme} ${className} ${outline ? 'outline' : ''}`}>
      <span className="button-text">{children}</span>
      <span className="button-arrow" />
    </Link>
  );
};

export default Button;
