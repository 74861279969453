import React from 'react';
import { Section, SmallButton, ThemeToggle, Icon } from 'components';
import './styles.scss';

const Usps = props => {
  const { data } = props;
  const { primary, items } = data;
  const { cta_link: to, cta_text: text, title, dark_mode: darkMode } = primary;

  // content checks
  const titleHtml = title?.html;
  const ctaText = text?.text;
  const ctaLink = to?.url;

  const hasTitle = !titleHtml ? 'no-title' : 'with-title';
  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className={`usps ${hasTitle}`}>
        {titleHtml && <div className="usps-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <div className="usps-columns">
          {items.map(item => {
            const { usp_description: description, usp_icon: icon, usp_title: uspTitle } = item;
            return (
              <div className="usps-column" key={uspTitle.text}>
                {icon && <Icon iconText={icon} />}
                <div>
                  {uspTitle && (
                    <div className="usps-column-title" dangerouslySetInnerHTML={{ __html: uspTitle.html }} />
                  )}
                  {description && (
                    <div className="usps-column-description" dangerouslySetInnerHTML={{ __html: description.html }} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {ctaText && ctaLink && (
          <div className="usps-action">
            <SmallButton to={ctaLink}>{ctaText}</SmallButton>
          </div>
        )}
      </Section>
    </ThemeToggle>
  );
};

export default Usps;
