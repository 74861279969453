import React from 'react';
import { Icon, FormikForm, Section, ThemeToggle } from 'components';
import './styles.scss';

const ContactForm = props => {
  const { data } = props;
  const { primary } = data;
  const { tagline, title, dark_mode: darkMode } = primary;

  // content checks
  const taglineHtml = tagline?.html;
  const titleHtml = title?.html;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="contact-form">
        <div className="contact-form-header">
          <div className="contact-form-header-icon-tagline">
            <Icon iconText="People talking" />
            <div className="contact-form-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />
          </div>
          <div className="contact-form-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        </div>
        <FormikForm />
      </Section>
    </ThemeToggle>
  );
};

export default ContactForm;
