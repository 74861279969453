import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section, SmallButton, ThemeToggle } from 'components';
import './styles.scss';

const OneColImageCard = props => {
  const { data } = props;
  const { primary } = data;
  const {
    author,
    author_position: position,
    card_content: content,
    cta_link: to,
    cta_text: text,
    image,
    dark_mode: darkMode,
  } = primary;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  // content checks
  const contentText = content?.text;
  const contentHtml = content?.html;
  const authorHtml = author?.html;
  const positionhtml = position?.html;
  const ctaText = text?.text;
  const ctaLink = to?.url;
  const hasImage = image?.url || image?.localFile;

  return (
    <ThemeToggle theme={theme}>
      <Section className="one-col-image-card">
        <div className="one-col-image-card-container">
          {hasImage && <Image className="one-col-image-card-image" image={image} />}
          {contentText && contentText.length > 1 && (
            <OnVisible className="one-col-image-card-card">
              <div className="one-col-image-card-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
              {(authorHtml || ctaText) && (
                <div className="one-col-image-card-bottom">
                  {authorHtml && (
                    <div>
                      {authorHtml && (
                        <div className="one-col-image-card-author" dangerouslySetInnerHTML={{ __html: authorHtml }} />
                      )}
                      {positionhtml && (
                        <div
                          className="one-col-image-card-position"
                          dangerouslySetInnerHTML={{ __html: positionhtml }}
                        />
                      )}
                    </div>
                  )}
                  {ctaText && ctaLink && (
                    <SmallButton className="one-col-image-card-cta" to={ctaLink} forceLightMode>
                      {ctaText}
                    </SmallButton>
                  )}
                </div>
              )}
            </OnVisible>
          )}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default OneColImageCard;
