import React from 'react';
import OnVisible from 'react-on-visible';
import { Link, SmallButton, Section, ThemeToggle, Icon } from 'components';
import './styles.scss';

const CardGrid = props => {
  const { data } = props;
  const { primary, items } = data;
  const { cards_icon: icon, title, dark_mode: darkMode } = primary;

  const titleHtml = title?.html;
  // const hasIcon = icon?.localFile || icon?.url;
  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="card-grid">
        <div className="card-grid-markers">
          {icon && <Icon iconText={icon} />}
          {titleHtml && <div className="card-grid-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        </div>
        <div className="card-grid-container">
          {items.map(item => {
            const { card_content: content, card_cta_link: link, card_cta_text: text, card_title: cardTitle } = item;
            const contentHtml = content?.html;
            const cardTitleHtml = cardTitle?.html;
            const ctaLink = link?.url;
            const ctaText = text?.text;
            return (
              <OnVisible className="card-grid-cell" key={cardTitle.text}>
                {cardTitleHtml && (
                  <Link to={ctaLink} className="card-grid-title">
                    <div dangerouslySetInnerHTML={{ __html: cardTitleHtml }} />
                  </Link>
                )}
                {contentHtml && <div className="card-grid-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
                {ctaLink && ctaText && (
                  <SmallButton to={ctaLink} className="card-cta">
                    {ctaText}
                  </SmallButton>
                )}
              </OnVisible>
            );
          })}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default CardGrid;
