import { useContext } from 'react';
import SchemaContext from '../context/schema';

const useSchema = () => {
  const schema = useContext(SchemaContext);

  return schema;
};

export default useSchema;
