import React, { useEffect } from 'react';
import { Navigation, Image, ThemeToggle, Link } from 'components';
import { siteName, description } from '../../../config/website';
import useSchema from '../../hooks/useSchema';
import facebookIcon from '../../images/facebook.svg';
import twitterIcon from '../../images/twitter.svg';
import linkedinIcon from '../../images/linkedin.svg';
import instagramIcon from '../../images/instagram.svg';
import maskableIcon from '../../images/maskable_icon.png';
import './styles.scss';

const navigationLinks = [
  { to: '/about', text: 'About', className: '' },
  { to: '/services', text: 'Services', className: '' },
  { to: '/projects', text: 'Work', className: '' },
  { to: '/contact-us', text: 'Contact', className: '' },
];

const facebook = 'https://www.facebook.com/Woolly-Mammoth-100497805258110';
const twitter = 'https://twitter.com/MammothDotTech';
const linkedin = 'https://www.linkedin.com/company/woollymammoth/';
const instagram = 'https://www.instagram.com/mammoth.tech/';

const maskableIconUrl = maskableIcon.replace('/static', 'https://mammoth.tech');

const localBusinessSchema = {
  name: siteName,
  description,
  telephone: '(02) 9121 6222',
  openingHours: 'Mo,Tu,We,Th,Fr 09:00-17:30',
  logo: maskableIconUrl,
  url: 'https://mammoth.tech',
  image: 'https://mammoth.tech/open-graph.jpg',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Darlinghurst',
    addressRegion: 'NSW',
    postalCode: '2010',
    streetAddress: '85 William Street',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '-33.874329',
    longitude: '151.215866',
  },
  sameAs: [facebook, twitter, linkedin, instagram],
};

const Footer = () => {
  const schema = useSchema();

  const { setLocalBusiness } = schema;

  useEffect(() => {
    setLocalBusiness(localBusinessSchema);
  }, []);

  return (
    <ThemeToggle theme="light-mode">
      <footer className="footer">
        <div className="wrapper">
          <div className="footer-socials">
            <Link to="https://www.facebook.com/Woolly-Mammoth-100497805258110" ariaLabel="Woolly Mammoth Facebook">
              <Image className="footer-social-icon" image={{ url: facebookIcon, alt: 'Woolly Mammoth Facebook' }} />
            </Link>
            <Link to="https://twitter.com/MammothDotTech" ariaLabel="Woolly Mammoth Twitter">
              <Image className="footer-social-icon" image={{ url: twitterIcon, alt: 'Woolly Mammoth Twitter' }} />
            </Link>
            <Link to="https://www.linkedin.com/company/woollymammoth/" ariaLabel="Woolly Mammoth LinkedIn">
              <Image className="footer-social-icon" image={{ url: linkedinIcon, alt: 'Woolly Mammoth LinkedIn' }} />
            </Link>
            <Link to="https://www.instagram.com/mammoth.tech/" ariaLabel="Woolly Mammoth Instagram">
              <Image className="footer-social-icon" image={{ url: instagramIcon, alt: 'Woolly Mammoth Instagram' }} />
            </Link>
          </div>
          <div className="footer-links">
            <Navigation classNamePrefix="footer" links={navigationLinks} />
          </div>
          <div className="sitemap">
            <span className="legal">© {new Date().getFullYear()} All rights reserved. </span>
          </div>
        </div>
      </footer>
    </ThemeToggle>
  );
};

export default Footer;
