import React from 'react';
import * as Icons from 'icons';
import './styles.scss';

const Icon = props => {
  const { iconText } = props;
  // Handle null slice_type prop
  if (!iconText) {
    console.error('Please pass iconText prop to the Icon component');
    return null;
  }

  // Convert slice type from snake_case to TitleCase
  const iconName = iconText
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1))
    .join('');

  // Get the slice component
  const CustomIcon = Icons[iconName];

  // Handle missing exported Slice
  if (!CustomIcon) {
    console.error(`Can't find Icon ${iconName}, are you sure it exists in the icons directory?`);
  }

  // Return Slice
  return <CustomIcon />;
};

export default Icon;
