import React from 'react';
import { Icon, Image, Section, ThemeToggle } from 'components';

import './styles.scss';

const Testimonials = ({ testimonials }) => {
  const { testimonial, tagline, title } = testimonials;
  // content checks
  const taglineHtml = tagline?.html;
  const titleHtml = title?.html;

  return (
    <ThemeToggle theme="light-mode">
      <Section className="testimonials">
        <div className="testimonials-header">
          <div className="testimonials-tagline-icon">
            <Icon iconText="Hugging People" />
            {taglineHtml && <div className="testimonials-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          </div>
          {titleHtml && <div className="testimonials-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        </div>
        <div className="testimonials-grid">
          {testimonial.map(item => {
            const { author, author_image: authorImg, content, position } = item;
            return (
              <div className="testimonials-column" key={author.text}>
                <div className="testimonial-quote" dangerouslySetInnerHTML={{ __html: content.html }} />
                <div className="testimonial-author">
                  <Image className="testimonial-author-image" image={authorImg} />
                  <div className="testimonial-author-text">
                    <div className="testimonial-author-name" dangerouslySetInnerHTML={{ __html: author.html }} />
                    <div className="testimonial-author-position" dangerouslySetInnerHTML={{ __html: position.html }} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default Testimonials;
