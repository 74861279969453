import React from 'react';
import { Link } from 'components';
import './styles.scss';

const SmallButton = props => {
  const { to, title, className = '', children, forceLightMode } = props;
  const ariaLabel = typeof children === 'string' ? children : null;
  return (
    <Link
      to={to}
      title={title || ''}
      className={`small-button ${className} ${forceLightMode ? 'no-dark-mode' : ''}`}
      ariaLabel={ariaLabel}
    >
      <span className="small-button-text">{children}</span>
      <div className="button-arrows">
        <span className="arrow" />
      </div>
    </Link>
  );
};

export default SmallButton;
