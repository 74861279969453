import React from 'react';
import { Image } from 'components';
import './styles.scss';

const CaseCard = props => {
  const { title, client, product, images, index } = props;

  return (
    <section className="case-card">
      <div className="case-card-grid">
        <div className="case-card-info">
          <span className="case-card-number">{`0${index + 1}`}</span>
          <span className="case-card-title">{title}</span>
          <span className="case-card-client">{`${client} | ${product}`}</span>
        </div>
        <div className="case-card-images">
          {images.map(image => {
            const { image: img, alt } = image;
            return <Image className="case-card-image" image={{ url: img, alt }} key={alt} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default CaseCard;
