import React, { useEffect } from 'react';
import Typed from 'typed.js';
import './styles.scss';

const TypedComponent = ({ textStrArr }) => {
  useEffect(() => {
    // Options for the Typed object
    const options = {
      strings: ['', ...textStrArr],
      typeSpeed: 40,
      backSpeed: 35,
      loop: true,
      backDelay: 1200,
      startDelay: 0,
    };

    // New Typed instance
    const typed = new Typed('.typed', options);

    // Destroy Typed instance on unmounting the component to prevent memory leaks
    return () => {
      typed.destroy();
    };
  }, [textStrArr]);

  return (
    <span className="typed">
      <span className="typed-init">{'\u00A0'}</span>
    </span>
  );
};

export default TypedComponent;
