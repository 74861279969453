import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import useHasScrolled from '../../hooks/useHasScrolled';

const ThemeToggle = props => {
  const { children, theme = 'light-mode', isPageWrapper } = props;
  const [initialised, setInitialsed] = useState(false);
  const [visible, setVisible] = useState(false);

  const hasScrolled = useHasScrolled();

  useEffect(() => {
    const ignorePageTheme = (isPageWrapper && initialised) || hasScrolled;
    const newTheme = theme;
    const oldTheme = theme === 'dark-mode' ? 'light-mode' : 'dark-mode';
    // User has scrolled - apply slice theme and ignore page theme
    if (visible && hasScrolled && !ignorePageTheme) {
      document.body.classList.add(newTheme);
      document.body.classList.remove(oldTheme);
      if (!initialised) setInitialsed(true);
    }
    // User is at top of the page - force page default theme
    if (!hasScrolled && isPageWrapper) {
      document.body.classList.add(theme);
      document.body.classList.remove(oldTheme);
    }
    // Hard reloaded at specific scroll position - apply slice theme
    if (visible && !isPageWrapper && hasScrolled) {
      document.body.classList.add(theme);
      document.body.classList.remove(oldTheme);
    }
  }, [visible, hasScrolled, isPageWrapper]);

  const handleVisibilityChange = isVisible => {
    setVisible(isVisible);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility="top" minTopValue={100}>
      {children}
    </VisibilitySensor>
  );
};

export default ThemeToggle;
