import React from 'react';
import { Section, SmallButton, ThemeToggle } from 'components';
import './styles.scss';

const LinksColumns = props => {
  const { data, location, servicePage } = props;
  const { pathname } = location;
  const { primary, items } = data;
  const { tagline, title, dark_mode: darkMode } = primary;

  // content checks
  const taglineHtml = tagline?.html;
  const titleHtml = title?.html;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="links-columns">
        <div className="links-columns-tagline-title">
          {taglineHtml && <div className="links-columns-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          {titleHtml && <div className="links-columns-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        </div>
        <div className="links-columns-links">
          {items.map(link => {
            const { link_text: text, link_url: url } = link;
            if (servicePage && url?.url && pathname.includes(url?.url)) {
              return null;
            }
            if (url?.url) {
              return (
                <SmallButton key={text?.text} to={url.url} className="links-columns-link">
                  {text.text}
                </SmallButton>
              );
            }
            return (
              <span key={text?.text} className="links-columns-link">
                {text.text}
              </span>
            );
          })}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default LinksColumns;
