import { useState, useEffect } from 'react';

const useHasScrolled = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 0 && !hasScrolled) {
      setHasScrolled(true);
    }

    if (scrollPosition < 1 && hasScrolled) {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return hasScrolled;
};

export default useHasScrolled;
