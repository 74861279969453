import React from 'react';
import { Image, Button, Section, ThemeToggle } from 'components';
import './styles.scss';

const InfoImageCols = props => {
  const { data } = props;
  const { primary } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    large_text: largeText,
    small_text: smallText,
    title,
    dark_mode: darkMode,
    small_text_gif: smallGif,
  } = primary;

  // // content checks
  const titleHtml = title?.html;
  const smallTextHtml = smallText?.html;
  const largeTextHtml = largeText?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="info-image-cols">
        {/* TEXT LEFT  */}
        <div className="info-image-cols-text">
          {titleHtml && <div className="info-image-cols-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          <div className="info-image-cols-grid">
            <div className="info-image-cols-content">
              {smallTextHtml && (
                <div
                  className="info-image-cols-smalltext with-gif"
                  data-gif={smallGif?.url}
                  dangerouslySetInnerHTML={{ __html: smallTextHtml }}
                />
              )}
              {largeTextHtml && (
                <div className="info-image-cols-largetext" dangerouslySetInnerHTML={{ __html: largeTextHtml }} />
              )}
            </div>
          </div>
        </div>
        {/* I M A G E   R I G H T  */}
        <div className="info-image-cols-image-cta">
          <Image className="info-image-cols-image" image={image} />
          {hasCta && (
            <Button className="info-image-cols-image-cta-button" to={ctaLink.url}>
              {ctaText.text}
            </Button>
          )}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default InfoImageCols;
