import React, { useState, useRef, useEffect } from 'react';
import { Image, Section, ThemeToggle } from 'components';
import './styles.scss';

const TeamCarousel = props => {
  const { data } = props;
  const { items, primary } = data;
  const { dark_mode: darkMode } = primary;

  // state
  const [activeIndex, setActiveIndex] = useState(0);
  const [cancelAutoPlay, setCancelAutoPlay] = useState(false);

  const timeout = useRef();

  useEffect(() => {
    if (!cancelAutoPlay) {
      timeout.current = setTimeout(() => {
        const nextIndex = activeIndex + 1 === items.length ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }, 7000);
    }
  }, [activeIndex, cancelAutoPlay]);

  const activeUser = items[activeIndex];

  const totalSlides = items?.length;
  const theme = darkMode ? 'dark-mode' : 'light-mode';

  const goToSlide = (event, index) => {
    event.preventDefault();
    clearTimeout(timeout.current);
    setCancelAutoPlay(true);
    setActiveIndex(index);
  };

  return (
    <ThemeToggle theme={theme}>
      <Section className="team-carousel">
        <Image className="team-carousel-current-portrait" image={activeUser.portrait} />
        <div className="team-carousel-slider-description">
          <div className="team-carousel-thumbnails">
            {items.map((item, index) => {
              const isActive = index === activeIndex;
              return (
                <a
                  href="#gotoprofile"
                  key={item?.name?.text}
                  className={`team-carousel-thumbnail ${isActive && !cancelAutoPlay ? 'active' : 'inactive'}`}
                  onClick={event => goToSlide(event, index)}
                >
                  <Image image={item?.portrait} className="team-carousel-portrait" />
                </a>
              );
            })}
          </div>
          {items.map((item, index) => {
            const { bio, job_title: jobTitle, name } = item;

            // content checks
            const bioHtml = bio?.html;
            const jobTitleText = jobTitle?.text;
            const nameHtml = name?.html;
            const nameText = name?.text;

            const isActive = index === activeIndex;

            return (
              <div key={nameText} className={`team-carousel-description ${isActive ? 'active' : 'inactive'}`}>
                <div className="team-carousel-slide-count">
                  <span className="team-carousel-slide-number">{`${index + 1} / ${totalSlides}`}</span>
                </div>
                <div className="team-carousel-bio" dangerouslySetInnerHTML={{ __html: bioHtml }} />
                <div className="team-carousel-proper-nouns">
                  <div className="team-carousel-name" dangerouslySetInnerHTML={{ __html: nameHtml }} />
                  <div className="team-carousel-job-title">{jobTitleText}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default TeamCarousel;
