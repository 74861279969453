import React from 'react';
import { SmallButton, Image, Section, ThemeToggle, Video } from 'components';
import './styles.scss';

const InfoCard = props => {
  const { data, noWrapper, isHome } = props;
  const { primary, items } = data;
  if (!primary) return null;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    homepage_image: homepageImage,
    video_bg: videoBg,
    video,
    title,
    darkmode,
    disable_onvisible: disableOnVisible,
    description,
  } = primary;

  // content checks
  const imageToUse = isHome ? homepageImage : image;
  const hasVideo = video?.url;
  const hasImage = !hasVideo && imageToUse?.url;
  const titleHtml = title?.html;
  const titleText = title?.text;
  const descriptionHtml = description?.html;
  const hasCta = ctaText?.text && ctaLink?.url;

  const theme = darkmode ? 'dark-mode' : 'light-mode';

  const serviceShortName = {
    'Web development': 'Web',
    'App development': 'App',
    'App design': 'App',
    'Technical consultation': 'Consulting',
    'UX/UI': 'UX/UI',
  };

  return (
    <ThemeToggle theme={theme}>
      <Section className="info-card" noWrapper={noWrapper} disableOnVisible={disableOnVisible}>
        <div className="info-card-container">
          {hasImage && <Image className="info-card-image" image={imageToUse} />}
          {hasVideo && (
            <Video
              className="info-card-image"
              src={hasVideo}
              placeholderColor={videoBg}
              id={`${titleText || 'infocard'}-video`}
              loop={false}
            />
          )}
          <div className="info-card-text-container">
            <div className="info-card-services">
              {items.map(item => {
                const { service } = item;
                return (
                  <span key={service} className="info-card-service">
                    {serviceShortName[service]}
                  </span>
                );
              })}
            </div>
            {titleHtml && <div className="info-card-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
            {descriptionHtml && (
              <div className="info-card-description" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            )}
            {hasCta && (
              <SmallButton className="info-card-cta" to={ctaLink.url}>
                {ctaText.text}
              </SmallButton>
            )}
          </div>
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default InfoCard;
