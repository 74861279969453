import React from 'react';
import { MultiStepForm as Form, Section, ThemeToggle } from 'components';
import './styles.scss';

const MultiStepForm = props => {
  const { data } = props;
  const { primary } = data;
  const { dark_mode: darkMode } = primary;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="contact-form">
        <Form />
      </Section>
    </ThemeToggle>
  );
};

export default MultiStepForm;
