import React from 'react';
import { Button, Image, Section, ThemeToggle, Typed } from 'components';
import './styles.scss';

const IntroHeader = props => {
  const { data } = props;
  const { primary } = data;
  const {
    cta_link: to,
    cta_text: text,
    header_content: content,
    hero_image: heroImage,
    tagline,
    title,
    dark_mode: darkMode,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasImage = heroImage?.localFile || heroImage?.url;
  const ctaText = text?.text;
  const ctaLink = to?.url;

  const titleHtmlNoUnwantedTags = titleHtml && titleHtml.replace('<p>', '').replace('</p>', '');

  // Handle rotating words
  const splitRotatingWords = titleHtmlNoUnwantedTags && titleHtmlNoUnwantedTags.split('%');
  const rotatingWords = splitRotatingWords.splice(1, 999).filter(word => word);
  const useRotatingWords = rotatingWords && rotatingWords.length > 2;

  // add class to render text version differently
  const textVersion = !hasImage && content ? 'text-version' : '';
  const rotatingWordsClass = useRotatingWords ? 'with-rotating-words' : '';

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className={`intro-header ${textVersion} ${rotatingWordsClass}`}>
        {taglineHtml && <div className="intro-header-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
        {titleHtml && !useRotatingWords && (
          <div className="intro-header-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        )}
        {useRotatingWords && (
          <div className="intro-header-title">
            <span>
              {splitRotatingWords[0]}
              <br />
              <Typed textStrArr={rotatingWords} />
            </span>
          </div>
        )}
        {contentHtml && <div className="intro-header-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {textVersion && ctaText && ctaLink ? (
          <Button to={ctaLink} className="intro-header-cta">
            {ctaText}
          </Button>
        ) : (
          <div className="intro-header-image-container">
            {ctaText && ctaLink && (
              <Button to={ctaLink} className="intro-header-cta">
                {ctaText}
              </Button>
            )}
            {hasImage && <Image className="intro-header-image" image={heroImage} />}
          </div>
        )}
      </Section>
    </ThemeToggle>
  );
};

export default IntroHeader;
