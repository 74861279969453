import React, { useState, useEffect } from 'react';
import { Link, Logo, WmIcon, Hamburger } from 'components';
import './styles.scss';

const navigationLinks = [
  { to: '/', text: 'Home', className: 'home' },
  { to: '/about', text: 'About us', className: '' },
  { to: '/services', text: 'Services', className: '' },
  { to: '/projects', text: 'Our Work', className: '' },
  { to: '/contact-us', text: 'Start a project', className: '' },
];

const Header = () => {
  const [stickNav, setStickNav] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [showNavBurger, setShowNavBurger] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true

  // S C R O L L   L O G I C   F O R   D E S K T O P
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickNav]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const headerEl = document.querySelector('.header');
    if (!headerEl) return null;
    const { offsetHeight: headerHeight } = headerEl;

    const stickNavAt = 300;
    const showNavAt = 600;

    if (scrollPosition >= stickNavAt && !stickNav) {
      setStickNav(true);
      document.body.style.paddingTop = `${headerHeight}px`;
    }

    if (scrollPosition < stickNavAt && stickNav) {
      setStickNav(false);
      document.body.style.paddingTop = '0px';
    }

    if (scrollPosition >= showNavAt && !showNav) {
      setShowNav(true);
    }

    if (scrollPosition < showNavAt && showNav) {
      setShowNav(false);
    }
  };

  // H A M B U R G E R   O N   M O B I L E

  // toggle main nav links for mobile
  const toggleNav = event => {
    event.preventDefault();
    setShowNavBurger(!showNavBurger);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K

  const handleEscKey = event => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNavBurger(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNavBurger && !bodyLocked) lockBody();
    if (!showNavBurger && bodyLocked) unlockBody();
  }, [showNavBurger]);

  // toggle visibility for mobile
  const hamburgerOpenClass = showNavBurger === true ? 'hamburger-open' : '';

  return (
    <>
      <header className={`header ${stickNav ? 'sticky' : ''} ${showNav ? 'show-nav' : ''} ${hamburgerOpenClass}`}>
        <div className="wrapper">
          {showNav ? <WmIcon /> : <Logo />}
          <Hamburger onClick={toggleNav} active={showNavBurger} />
          <nav className="header-navigation">
            <div>
              {navigationLinks.map((link, index) => (
                <Link
                  key={link.text}
                  activeClassName="active"
                  className={`header-navigation-link ${link.className || ''}`}
                  to={link.to}
                  style={{ transitionDelay: `0.${3 * index + 1}s` }}
                >
                  {link.text}
                </Link>
              ))}
            </div>
            <div className="header-navigation-footer">
              <h3 className="header-navigation-footer-title">Get in touch</h3>
              <a href="mailto:hey@mammoth.tech" className="header-navigation-footer-content">
                hey@mammoth.tech
              </a>
              <a href="tel:+61291216222" className="header-navigation-footer-content">
                (02) 9121 6222
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
