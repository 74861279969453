import React, { useState, createContext } from 'react';

const defaultSchemaContext = {
  QAPage: [],
};

const SchemaContext = createContext(defaultSchemaContext);

export const SchemaProvider = props => {
  const { children } = props;
  const [FAQPage, setFAQPage] = useState([]);
  const [LocalBusiness, setLocalBusiness] = useState(null);
  const [Organization, setOrganization] = useState(null);
  const [Review, setReview] = useState(null);
  const [VideoObject, setVideoObject] = useState(null);

  const pushFAQ = QAObj => {
    const clonedQAPage = [...FAQPage];
    clonedQAPage.push({
      '@type': 'Question',
      ...QAObj,
    });
    setFAQPage(clonedQAPage);
  };

  const hasFAQ = FAQPage.length > 0;

  const FAQMarkup = hasFAQ
    ? `
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": ${JSON.stringify(FAQPage.map(schema => schema))}
        }
      </script>`
    : null;

  const LocalBusinessMarkup = LocalBusiness
    ? `
<script type="application/ld+json">
  ${JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    ...LocalBusiness,
  })}
</script>`
    : null;

  const OrganizationMarkup = Organization
    ? `
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          ${JSON.stringify(Organization)}
        }
      </script>`
    : null;

  const ReviewMarkup = Review
    ? `
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Review",
          ${JSON.stringify(Review)}
        }
      </script>`
    : null;

  const VideoObjectMarkup = VideoObject
    ? `
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "VideoObject",
          ${JSON.stringify(...VideoObject)}
        }
      </script>`
    : null;

  return (
    <SchemaContext.Provider
      value={{
        ...defaultSchemaContext,
        pushFAQ,
        setLocalBusiness,
        setOrganization,
        setReview,
        setVideoObject,
      }}
    >
      {children}
      {FAQMarkup && <div dangerouslySetInnerHTML={{ __html: FAQMarkup }} />}
      {LocalBusinessMarkup && <div dangerouslySetInnerHTML={{ __html: LocalBusinessMarkup }} />}
      {OrganizationMarkup && <div dangerouslySetInnerHTML={{ __html: OrganizationMarkup }} />}
      {ReviewMarkup && <div dangerouslySetInnerHTML={{ __html: ReviewMarkup }} />}
      {VideoObjectMarkup && <div dangerouslySetInnerHTML={{ __html: VideoObjectMarkup }} />}
    </SchemaContext.Provider>
  );
};

export const SchemaConsumer = SchemaContext.Consumer;

export default SchemaContext;
