import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { Image } from 'components';
import './styles.scss';

const Section = props => {
  const { className, children, bgImage, noWrapper, withMargin, clientOnly, disableOnVisible } = props;
  const containClassName = `section ${className || ''} ${withMargin ? 'with-margin' : ''}`;

  const [isVisible, setIsVisible] = useState(false);

  if (disableOnVisible) {
    return (
      <section className={`${containClassName} visible`}>
        <>
          {bgImage && <Image image={bgImage} className="section-bg" alt="Background Image" />}
          {noWrapper ? children : <div className="wrapper">{children}</div>}
        </>
      </section>
    );
  }

  if (clientOnly && !isVisible)
    return <OnVisible className={containClassName} wrappingElement="section" onChange={setIsVisible} percent={0} />;

  return (
    <OnVisible className={containClassName} wrappingElement="section" onChange={setIsVisible}>
      {bgImage && <Image image={bgImage} className="section-bg" alt="Background Image" />}
      {noWrapper ? children : <div className="wrapper">{children}</div>}
    </OnVisible>
  );
};

export default Section;
