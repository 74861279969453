import React from 'react';
import { Section, ThemeToggle, Button, Icon } from 'components';
import './styles.scss';

const ContactCta = props => {
  const { data } = props;
  const { primary } = data;
  const { content, cta_link: to, cta_text: text, icon, tagline, title, dark_mode: darkMode, gif } = primary;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const contentHtml = content?.html;
  const ctaText = text?.text;
  const ctaLink = to?.url;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="contact-cta">
        {icon && <Icon iconText={icon} />}
        {taglineHtml && <div className="contact-cta-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
        <div className="contact-cta-grid-container">
          {titleHtml && <div className="contact-cta-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && (
            <div
              className="contact-cta-content with-gif"
              dangerouslySetInnerHTML={{ __html: contentHtml }}
              data-gif={gif?.url}
            />
          )}
          {ctaText && ctaLink && (
            <div className="content-cta-action">
              <Button className="button" to={ctaLink}>
                {ctaText}
              </Button>
            </div>
          )}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default ContactCta;
