import React from 'react';
import { Link } from 'components';
import './styles.scss';

const WmIcon = () => (
  <Link to="/" ariaLabel="Woolly Mammoth">
    <svg width="80px" height="43px" viewBox="0 0 184 98" version="1.1" className="wm-icon">
      <g id="WM01_Branding_St03V1-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Fill-16"
          fill="#000000"
          points="17.9616 41.0484 35.8576 41.0484 32.6536 0.4234 14.2836 0.4234"
          className="letter-vector"
        ></polygon>
        <polygon
          id="Fill-17"
          fill="#00F7FF"
          points="1.7588 60.894 19.929 60.894 17.9616 41.0484 -1.42108547e-14 41.048"
          className="wm-icon-left"
        ></polygon>
        <polygon
          id="Fill-18"
          fill="#000000"
          points="103.6178 0.4888 85.6338 0.4888 80.4988 59.6358 80.5648 61.0028 63.1808 19.0658 54.8248 19.0658 37.4958 60.8468 37.4958 60.8938 37.4768 60.8938 37.4318 61.0028 37.4238 60.8938 19.7588 60.8938 23.1028 97.8418 37.7738 97.9078 59.0028 46.7768 80.2198 97.9078 95.3068 97.9078"
          className="letter-vector"
        ></polygon>
        <polygon
          id="Fill-19"
          fill="#000000"
          points="165.9118 57.2828 148.0158 57.2828 151.2198 97.9078 169.5898 97.9078"
          className="letter-vector"
        ></polygon>
        <polygon
          id="Fill-20"
          fill="#0000FF"
          points="166.0158 57.283 183.929 57.283 182.522949 37.437 164 37.437"
          className="wm-icon-right"
        ></polygon>
        <polygon
          id="Fill-21"
          fill="#000000"
          points="160.7702 0.4898 146.1002 0.4238 124.8712 51.5538 103.6532 0.4888 88.5662 0.4888 80.0082 97.8418 93.2402 97.8418 100.7602 30.8988 103.3092 37.3288 120.6922 79.2648 129.0492 79.2648 146.3772 37.4838 146.3772 37.4368 146.3962 37.4368 146.4412 37.3288 146.4502 37.4368 164.1152 37.4368"
          className="letter-vector"
        ></polygon>
      </g>
    </svg>
  </Link>
);

export default WmIcon;
